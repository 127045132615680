body {
  margin: 0;
  padding: 0;
  font-family: "infini", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'adieu';
  src: url('./assets/fonts/adieu/adieu-regular.eot');
  src: url('./assets/fonts/adieu/adieu-regular.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/adieu/adieu-regular.woff2') format('woff2'),
       url('./assets/fonts/adieu/adieu-regular.woff') format('woff'),
       url('./assets/fonts/adieu/adieu-regular.ttf') format('truetype'),
       url('./assets/fonts/adieu/adieu-regular.svg#adieuregular') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'infini';
  src: url('./assets/fonts/infini/regular/infini-regular.eot');
  src: url('./assets/fonts/infini/regular/infini-regular.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/infini/regular/infini-regular.woff2') format('woff2'),
       url('./assets/fonts/infini/regular/infini-regular.woff') format('woff'),
       url('./assets/fonts/infini/regular/infini-regular.ttf') format('truetype'),
       url('./assets/fonts/infini/regular/infini-regular.svg#infiniregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'infini';
  src: url('./assets/fonts/infini/bold/infini-bold.eot');
  src: url('./assets/fonts/infini/bold/infini-bold.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/infini/bold/infini-bold.woff2') format('woff2'),
       url('./assets/fonts/infini/bold/infini-bold.woff') format('woff'),
       url('./assets/fonts/infini/bold/infini-bold.ttf') format('truetype'),
       url('./assets/fonts/infini/bold/infini-bold.svg#infinibold') format('svg');
  font-weight: bold;
  font-style: normal;

}

@font-face {
  font-family: 'infini';
  src: url('./assets/fonts/infini/italic/infini-italic.eot');
  src: url('./assets/fonts/infini/italic/infini-italic.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/infini/italic/infini-italic.woff2') format('woff2'),
       url('./assets/fonts/infini/italic/infini-italic.woff') format('woff'),
       url('./assets/fonts/infini/italic/infini-italic.ttf') format('truetype'),
       url('./assets/fonts/infini/italic/infini-italic.svg#infiniitalic') format('svg');
  font-weight: normal;
  font-style: italic;

}

@font-face {
  font-family: 'infinipicto';
  src: url('./assets/fonts/infini/picto/infini-picto.eot');
  src: url('./assets/fonts/infini/picto/infini-picto.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/infini/picto/infini-picto.woff2') format('woff2'),
       url('./assets/fonts/infini/picto/infini-picto.woff') format('woff'),
       url('./assets/fonts/infini/picto/infini-picto.ttf') format('truetype'),
       url('./assets/fonts/infini/picto/infini-picto.svg#infini_pictoregular') format('svg');
  font-weight: normal;
  font-style: normal;

}
