.Duik {
    box-sizing: border-box;
    color: #fff;
    display: block;
    cursor: pointer;
    float: left;
    width: 100%;
    height: 23vh;
    margin: 0;
    padding: 1.8vw 1.4vw;
    overflow: hidden;
    background-color: #001a29;
    -webkit-transition: all 1s;
    transition: all 1s;
    transition-timing-function: ease;
    background-image:url('../../../assets/images/de-toren-logo-blend.png'), url('./assets/images/duik-in-het-onbekende.png');
    background-repeat: no-repeat, no-repeat;
    background-position: 50vw -23vh, left 12vh;
    background-size:  auto 92vh, 100% auto;
    background-blend-mode: screen, normal;
    box-shadow: inset 0 -20px 20px -20px rgba(0,0,0,0.80);
    -moz-box-shadow: inset 0 -20px 20px -20px rgba(0,0,0,0.80);
    -webkit-box-shadow: inset 0 -20px 20px -20px rgba(0,0,0,0.80);
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .Duik {
        background-image: url('../../../assets/images/de-toren-logo.png'), url('./assets/images/duik-in-het-onbekende.png');
    }
}

@supports (-ms-ime-align:auto) {
    .Duik {
        background-image: url('../../../assets/images/de-toren-logo.png'), url('./assets/images/duik-in-het-onbekende.png');
    }
}

.Duik h1 {
    font-family: 'adieu';
    font-weight: normal;
    padding: 0;
    margin: 0;
    font-size: 2.4vw;
    width: 35vw;
}

.Duik p {
    font-size: 1.3vw;
    width: 35vw;
    margin-top: 23vh;
    -webkit-transition: margin-top 1s;
    transition: margin-top 1s;
    transition-timing-function: ease;
}

.Duik button {
    font-size: 1.7vw;
    padding: 1vw 2vw;
    background-color: transparent;
    color: #fff;
    font-family: 'infini';
    border: 2px solid white;
    cursor: pointer;
}

.Duik.Open {
    height: 44vh;
    cursor: initial;
    background-position: 50vw -16vh, left 30vh;
}

.Duik.Open p {
    margin-top: 0;
}

.Duik.Medium {
    height: 16vh;
    background-position: 50vw -16vh, left 10vh;
}
.Duik.Medium.ontscheppingOpen {
    background-position: 50vw -44vh, left 10vh;
}

.Duik.Small {
    height: 16vh;
    padding: 0.8vw 1.4vw;
    background-position: 50vw -10vw, left 10vh;
}

.Duik.Smallest {
    height: 6vh;
    padding: 3vh 1.4vw;
    background-position: 50vw -6.4vw, left 10vh;
}

@media (max-width: 1024px) {
    .Duik {
        background-image: url('./assets/images/duik-in-het-onbekende-mobile.png');
        background-repeat: no-repeat;
        background-position: left 12vh;
        background-size: 100% auto;
        background-blend-mode: normal;
   
    }

    .Duik.Open {
        background-position: left 30vh;
        background-size: 100% auto;
    }

    .Duik.Medium, .Duik.Medium.ontscheppingOpen {
        background-position: left 10vh;
        background-size: 100% auto;
    }

    .Duik.Small {
        background-position: left 9vh;
        background-size: 100% auto;
    }

    .Duik.Smallest {
        background-position: left 9vh;
        background-size: 100% auto;
    }
    

    .Duik h1 {
        font-size: 7.5vw;
        width: 100%;
        text-align: center;
        margin: 2vh auto 0.5vw auto;
    }

    .Duik.Medium h1 {
        margin: 4vh auto 0.5vw auto;
    }


    .Duik.Small h1 {
        margin: 3vh auto 0.5vw auto;
    }

    .Duik p {
        box-sizing: border-box;
        font-size: 3.5vw;
        width: 100%;
        padding: 0 10%;
        text-align: center;
    }

    .Duik button {
        display: block;
        margin: auto;
    }
}

@media (max-width: 640px) {
    .Duik p {
        font-size: 5vw;
    }

    .Duik button {
        font-size: 5vw;
        padding: 2vw 4vw;
    }

}