.Ontschepping {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    float: left;
    width: 100%;
    height: 23vh;
    margin: 0;
    padding: 1.4vw 1.4vw;
    background-color: #ffc3a0;
    background-image: url('./assets/images/illustratie-schepping.png'), url('../../../assets/images/de-toren-logo-blend.png');
    background-repeat: no-repeat, no-repeat;
    background-position: right bottom, 50vw top;
    background-size: 50vw auto, auto 92vh;
    background-blend-mode: normal, screen;
    overflow: hidden;
    transition: all 1s;
    transition-timing-function: ease;
    box-shadow: inset 0 -20px 20px -20px rgba(0,0,0,0.80);
    -moz-box-shadow: inset 0 -20px 20px -20px rgba(0,0,0,0.80);
    -webkit-box-shadow: inset 0 -20px 20px -20px rgba(0,0,0,0.80);
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .Ontschepping {
        background-image: url('./assets/images/illustratie-schepping.png'), url('../../../assets/images/de-toren-logo.png');
    }
}

@supports (-ms-ime-align:auto) {
    .Ontschepping {
        background-image: url('./assets/images/illustratie-schepping.png'), url('../../../assets/images/de-toren-logo.png');
    }
}

.Ontschepping h1 {
    font-style: italic;
    font-weight: normal;
    font-size: 2.4vw;
    width: 35vw;
    color: #f34553;
    margin: 0 0 0.5vw 0;
    transition: all 1s;
    transition-timing-function: ease;
}
.Ontschepping h1 .white {
    font-weight: normal;
    color: white;
}


.Ontschepping p {
    font-size: 1.3vw;
    width: 35vw;
    margin-top: 23vh;
    -webkit-transition: margin-top 1s;
    transition: margin-top 1s;
    transition-timing-function: ease;
}

.Ontschepping button {
    font-size: 1.7vw;
    padding: 1vw 2vw;
    background-color: #f34553;
    color: #fff;
    font-family: 'infini';
    border: 2px solid transparent;
    cursor: pointer;
}

.Ontschepping.Open {
    height: 44vh;
    cursor: initial;
}

.Ontschepping.Open p {
    margin-top: 0;
}

.Ontschepping.Medium {
    height: 16vh;
}

.Ontschepping.Small {
    height: 16vh;
    padding: 0.8vw 1.4vw;
    background-position: right bottom, 50vw -2vh;
}

.Ontschepping.Smallest {
    height: 6vh;
    padding: 0.8vw 1.4vw;
}

@media (max-width: 1024px) {
    .Ontschepping {
        background-color: #ffc3a0;
        background-image: url('./assets/images/illustratie-schepping-mobile.png');
        background-repeat: no-repeat;
        background-position: left top;
        background-size:  100% auto;
        background-blend-mode: normal;
    }

    .Ontschepping h1 {
        font-size: 6vw;
        width: 100%;
        text-align: center;
        margin: 10vh auto 0.5vw auto;
    }

    .Ontschepping.Medium h1 {
        margin: 4vh auto 0.5vw auto;
    }


    .Ontschepping.Small h1 {
        margin: 3vh auto 0.5vw auto;
    }

    .Ontschepping.Smallest h1 {
        margin: 3vh auto 0.5vw auto;
    }

    .Ontschepping p {
        box-sizing: border-box;
        font-size: 3.5vw;
        width: 100%;
        padding: 0 10%;
        text-align: center;
    }

    .Ontschepping button {
        display: block;
        margin: auto;
    }
}

@media (max-width: 640px) {
    .Ontschepping p {
        font-size: 4.8vw;
    }

    .Ontschepping button {
        font-size: 5vw;
        padding: 2vw 4vw;
    }

}