body {
  margin: 0;
  padding: 0;
  font-family: "infini", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'adieu';
  src: url(/static/media/adieu-regular.d6bb600b.eot);
  src: url(/static/media/adieu-regular.d6bb600b.eot?#iefix) format('embedded-opentype'),
       url(/static/media/adieu-regular.6285def9.woff2) format('woff2'),
       url(/static/media/adieu-regular.95ef4e3d.woff) format('woff'),
       url(/static/media/adieu-regular.4a02eb34.ttf) format('truetype'),
       url(/static/media/adieu-regular.d0ea743f.svg#adieuregular) format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'infini';
  src: url(/static/media/infini-regular.e20d4c96.eot);
  src: url(/static/media/infini-regular.e20d4c96.eot?#iefix) format('embedded-opentype'),
       url(/static/media/infini-regular.95711be6.woff2) format('woff2'),
       url(/static/media/infini-regular.487ae1d2.woff) format('woff'),
       url(/static/media/infini-regular.1ef6517e.ttf) format('truetype'),
       url(/static/media/infini-regular.24ff6f13.svg#infiniregular) format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'infini';
  src: url(/static/media/infini-bold.3d14bfe1.eot);
  src: url(/static/media/infini-bold.3d14bfe1.eot?#iefix) format('embedded-opentype'),
       url(/static/media/infini-bold.60375f06.woff2) format('woff2'),
       url(/static/media/infini-bold.7dcec0dd.woff) format('woff'),
       url(/static/media/infini-bold.2120df34.ttf) format('truetype'),
       url(/static/media/infini-bold.584b0a80.svg#infinibold) format('svg');
  font-weight: bold;
  font-style: normal;

}

@font-face {
  font-family: 'infini';
  src: url(/static/media/infini-italic.d83cb57e.eot);
  src: url(/static/media/infini-italic.d83cb57e.eot?#iefix) format('embedded-opentype'),
       url(/static/media/infini-italic.7c69d018.woff2) format('woff2'),
       url(/static/media/infini-italic.795a557a.woff) format('woff'),
       url(/static/media/infini-italic.4c78a307.ttf) format('truetype'),
       url(/static/media/infini-italic.1ae9564d.svg#infiniitalic) format('svg');
  font-weight: normal;
  font-style: italic;

}

@font-face {
  font-family: 'infinipicto';
  src: url(/static/media/infini-picto.18a0f0d8.eot);
  src: url(/static/media/infini-picto.18a0f0d8.eot?#iefix) format('embedded-opentype'),
       url(/static/media/infini-picto.939331d7.woff2) format('woff2'),
       url(/static/media/infini-picto.7524a247.woff) format('woff'),
       url(/static/media/infini-picto.b095ba01.ttf) format('truetype'),
       url(/static/media/infini-picto.67a06222.svg#infini_pictoregular) format('svg');
  font-weight: normal;
  font-style: normal;

}

.Logline_Logline__2aZEo {
    box-sizing: border-box;
    display: block;
    float: left;
    width: 100%;
    height: 0;
    margin: 0;
    padding: 0 1.4vw;
    overflow: hidden;
    -webkit-transition: height 1.5s;
    transition: height 1.5s;
    -webkit-transition-timing-function: ease;
            transition-timing-function: ease;
    text-align: center;
}

.Logline_Logline__2aZEo.Logline_Open__1Ppuo {
    height: 30vh;
}

.Logline_Logline__2aZEo p {
    font-size: 2vw;
    padding: 0 20vw;
}

.Logline_Logline__2aZEo button {
    font-family: 'infini';
    font-size: 1.3vw;
    padding: 1vw 2vw;
    display: block;
    margin: auto;
    background: white;
    border: 1px solid black;
}

.Logline_Logline__2aZEo button:focus {
    outline: none;
}

@media (max-width: 640px) {
    .Logline_Logline__2aZEo.Logline_Open__1Ppuo {
        height: 15vh;
    }
    .Logline_Logline__2aZEo p {
        font-size: 3vw;
    }
    .Logline_Logline__2aZEo button {
        font-size: 3vw;
    }
}
.Menu_Menu__3x8k1 {
    box-sizing: border-box;
    background-color: black;
    color: white;
    display: block;
    float: left;
    width: 100%;
    height: 0;
    margin: 0;
    padding: 0 1.4vw;
    overflow: hidden;
    overflow-y: scroll;
    -webkit-transition: all 1s;
    transition: all 1s;
    -webkit-transition-timing-function: ease;
            transition-timing-function: ease;
    scroll-behavior: smooth;
}

.Menu_Menu__3x8k1.Menu_Open__nWq0I {
    height: auto;
    padding-bottom: 2vh;
    height: 68vh;
    max-height: 68vh;
    overflow-x: none;
    overflow-y: auto;
}

.Menu_Menu__3x8k1 .Menu_Tvmenu__2xXs_ {
    width: 98%;
    margin: 0 1%;
    height: auto;
}
.Menu_Menu__3x8k1 .Menu_Tvmenu__2xXs_ h2 {
    width: 100%;
    border-bottom: 1px solid #ababab;
}
.Menu_Menu__3x8k1 .Menu_Tvmenu__2xXs_ a {
    color: white;
    text-decoration: underline;
}


.Menu_Menu__3x8k1 .Menu_Tvmenu__2xXs_ p {
    text-align: left;
}

.Menu_Menu__3x8k1 .Menu_Tvmenu__2xXs_ .Menu_Gridcontainer__sZp0L {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 1em;
    margin: 0;
    padding: 0;
}

/*.Menu .Tvmenu .Aflevering {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    background-color: #3e3e3e;
    margin: 0;
    padding: 0;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    padding-top:56.25%;
    height: 0;
    cursor: pointer;
    pointer-events: none;
    text-align: center;
}

.Menu .Tvmenu .Gridcontainer .Aflevering {
    display: inline-grid;
}*/

.Menu_Menu__3x8k1 .Menu_Tvmenu__2xXs_ .Menu_Wrapper__2vxkt a {
    text-align: center;
    text-decoration: none;
    position: absolute;
    top: 0; bottom: 0; left: 0; right: 0;
    width: 100%;
}

.Menu_Menu__3x8k1 .Menu_Tvmenu__2xXs_ .Menu_Wrapper__2vxkt a:focus {
    outline: none;
}

.Menu_Menu__3x8k1 .Menu_Tvmenu__2xXs_ .Menu_Wrapper__2vxkt p, .Menu_Menu__3x8k1 .Menu_Tvmenu__2xXs_ .Menu_Wrapper__2vxkt h3 {
    color: white;
    text-align: center;
    text-decoration: none;
    text-shadow: 2px 2px black;
    cursor: pointer;
    line-height: 1.5em;
    pointer-events: none;
}

.Menu_Menu__3x8k1 .Menu_Tvmenu__2xXs_ .Menu_Wrapper__2vxkt h3 {
    font-size: 1.5em;
    margin-top: -38%;
    text-transform: capitalize;
    font-weight: normal;

}

.Menu_Menu__3x8k1 .Menu_Tvmenu__2xXs_ .Menu_Wrapper__2vxkt p {
    font-size: 1em;
}

.Menu_Menu__3x8k1 .Menu_Tvmenu__2xXs_ ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.Menu_Menu__3x8k1 .Menu_Tvmenu__2xXs_ h3 {
    text-transform: uppercase;
}

.Menu_Menu__3x8k1 .Menu_Wrapper__2vxkt {
    width: 100%;
    padding-bottom: 56%;
    position: relative;
}

@media (max-width: 640px) {
    .Menu_Menu__3x8k1 {
        padding: 0 3vw;
    }

    .Menu_Menu__3x8k1.Menu_Open__nWq0I {
        padding: 1.5vw 3vw;
    }

    .Menu_Menu__3x8k1 .Menu_Tvmenu__2xXs_ {
        float: none;
        width: 100%;
        margin: 0;
    }
}


/* Slider */
.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

.slick-dots {
    margin-left: 0;
}

.slick-dots {
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}

.slick-dots li, .slick-dots li button {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.slick-dots li {
    position: relative;
    display: inline-block;
    margin: 0 5px;
    padding: 0;
}

.slick-dots li button:before {
    font-family: slick;
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: "\2022";
    text-align: center;
    opacity: .25;
    color: #000;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-slider:before {
    content: '';
    position: absolute;
    box-shadow: inset 30px 0 30px -30px #000,
                inset -30px 0 30px -30px #000;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    pointer-events: none;
    cursor: pointer;
}

.slick-slider img {
    width: 98%;
    height: auto;
    align-self: center;
    margin: 0 auto;
}

.slick-arrow.slick-prev, .slick-arrow.slick-next {
    background-color: rgba(0, 0, 0, 0);
    border: none;
    position: absolute;
    background-image: url(/static/media/prevnext.fbd36421.svg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
    top: 0;
    z-index: 200;
    height: 100%;
    width: 6vw;
    text-indent:-9999px;
    cursor: pointer;
}

.slick-arrow.slick-prev:focus, .slick-arrow.slick-next:focus  {
    outline: none;
}

.slick-arrow.slick-prev {
    left: 0;
}

.slick-arrow.slick-next {
    right: 0;
    -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
}
.Ontschepping_Ontschepping__Z1g1M {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    float: left;
    width: 100%;
    height: 23vh;
    margin: 0;
    padding: 1.4vw 1.4vw;
    background-color: #ffc3a0;
    background-image: url(/static/media/illustratie-schepping.fe078d12.png), url(/static/media/de-toren-logo-blend.d109e3c7.png);
    background-repeat: no-repeat, no-repeat;
    background-position: right bottom, 50vw top;
    background-size: 50vw auto, auto 92vh;
    background-blend-mode: normal, screen;
    overflow: hidden;
    -webkit-transition: all 1s;
    transition: all 1s;
    -webkit-transition-timing-function: ease;
            transition-timing-function: ease;
    box-shadow: inset 0 -20px 20px -20px rgba(0,0,0,0.80);
    -moz-box-shadow: inset 0 -20px 20px -20px rgba(0,0,0,0.80);
    -webkit-box-shadow: inset 0 -20px 20px -20px rgba(0,0,0,0.80);
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .Ontschepping_Ontschepping__Z1g1M {
        background-image: url(/static/media/illustratie-schepping.fe078d12.png), url(/static/media/de-toren-logo.1f89e0c7.png);
    }
}

@supports (-ms-ime-align:auto) {
    .Ontschepping_Ontschepping__Z1g1M {
        background-image: url(/static/media/illustratie-schepping.fe078d12.png), url(/static/media/de-toren-logo.1f89e0c7.png);
    }
}

.Ontschepping_Ontschepping__Z1g1M h1 {
    font-style: italic;
    font-weight: normal;
    font-size: 2.4vw;
    width: 35vw;
    color: #f34553;
    margin: 0 0 0.5vw 0;
    -webkit-transition: all 1s;
    transition: all 1s;
    -webkit-transition-timing-function: ease;
            transition-timing-function: ease;
}
.Ontschepping_Ontschepping__Z1g1M h1 .Ontschepping_white__u-NoN {
    font-weight: normal;
    color: white;
}


.Ontschepping_Ontschepping__Z1g1M p {
    font-size: 1.3vw;
    width: 35vw;
    margin-top: 23vh;
    -webkit-transition: margin-top 1s;
    transition: margin-top 1s;
    -webkit-transition-timing-function: ease;
            transition-timing-function: ease;
}

.Ontschepping_Ontschepping__Z1g1M button {
    font-size: 1.7vw;
    padding: 1vw 2vw;
    background-color: #f34553;
    color: #fff;
    font-family: 'infini';
    border: 2px solid transparent;
    cursor: pointer;
}

.Ontschepping_Ontschepping__Z1g1M.Ontschepping_Open__Y-gxJ {
    height: 44vh;
    cursor: auto;
    cursor: initial;
}

.Ontschepping_Ontschepping__Z1g1M.Ontschepping_Open__Y-gxJ p {
    margin-top: 0;
}

.Ontschepping_Ontschepping__Z1g1M.Ontschepping_Medium__epVl- {
    height: 16vh;
}

.Ontschepping_Ontschepping__Z1g1M.Ontschepping_Small__17ZLq {
    height: 16vh;
    padding: 0.8vw 1.4vw;
    background-position: right bottom, 50vw -2vh;
}

.Ontschepping_Ontschepping__Z1g1M.Ontschepping_Smallest__X147j {
    height: 6vh;
    padding: 0.8vw 1.4vw;
}

@media (max-width: 1024px) {
    .Ontschepping_Ontschepping__Z1g1M {
        background-color: #ffc3a0;
        background-image: url(/static/media/illustratie-schepping-mobile.922ffe6f.png);
        background-repeat: no-repeat;
        background-position: left top;
        background-size:  100% auto;
        background-blend-mode: normal;
    }

    .Ontschepping_Ontschepping__Z1g1M h1 {
        font-size: 6vw;
        width: 100%;
        text-align: center;
        margin: 10vh auto 0.5vw auto;
    }

    .Ontschepping_Ontschepping__Z1g1M.Ontschepping_Medium__epVl- h1 {
        margin: 4vh auto 0.5vw auto;
    }


    .Ontschepping_Ontschepping__Z1g1M.Ontschepping_Small__17ZLq h1 {
        margin: 3vh auto 0.5vw auto;
    }

    .Ontschepping_Ontschepping__Z1g1M.Ontschepping_Smallest__X147j h1 {
        margin: 3vh auto 0.5vw auto;
    }

    .Ontschepping_Ontschepping__Z1g1M p {
        box-sizing: border-box;
        font-size: 3.5vw;
        width: 100%;
        padding: 0 10%;
        text-align: center;
    }

    .Ontschepping_Ontschepping__Z1g1M button {
        display: block;
        margin: auto;
    }
}

@media (max-width: 640px) {
    .Ontschepping_Ontschepping__Z1g1M p {
        font-size: 4.8vw;
    }

    .Ontschepping_Ontschepping__Z1g1M button {
        font-size: 5vw;
        padding: 2vw 4vw;
    }

}
.Duik_Duik__1JANo {
    box-sizing: border-box;
    color: #fff;
    display: block;
    cursor: pointer;
    float: left;
    width: 100%;
    height: 23vh;
    margin: 0;
    padding: 1.8vw 1.4vw;
    overflow: hidden;
    background-color: #001a29;
    -webkit-transition: all 1s;
    transition: all 1s;
    -webkit-transition-timing-function: ease;
            transition-timing-function: ease;
    background-image:url(/static/media/de-toren-logo-blend.d109e3c7.png), url(/static/media/duik-in-het-onbekende.933602e6.png);
    background-repeat: no-repeat, no-repeat;
    background-position: 50vw -23vh, left 12vh;
    background-size:  auto 92vh, 100% auto;
    background-blend-mode: screen, normal;
    box-shadow: inset 0 -20px 20px -20px rgba(0,0,0,0.80);
    -moz-box-shadow: inset 0 -20px 20px -20px rgba(0,0,0,0.80);
    -webkit-box-shadow: inset 0 -20px 20px -20px rgba(0,0,0,0.80);
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .Duik_Duik__1JANo {
        background-image: url(/static/media/de-toren-logo.1f89e0c7.png), url(/static/media/duik-in-het-onbekende.933602e6.png);
    }
}

@supports (-ms-ime-align:auto) {
    .Duik_Duik__1JANo {
        background-image: url(/static/media/de-toren-logo.1f89e0c7.png), url(/static/media/duik-in-het-onbekende.933602e6.png);
    }
}

.Duik_Duik__1JANo h1 {
    font-family: 'adieu';
    font-weight: normal;
    padding: 0;
    margin: 0;
    font-size: 2.4vw;
    width: 35vw;
}

.Duik_Duik__1JANo p {
    font-size: 1.3vw;
    width: 35vw;
    margin-top: 23vh;
    -webkit-transition: margin-top 1s;
    transition: margin-top 1s;
    -webkit-transition-timing-function: ease;
            transition-timing-function: ease;
}

.Duik_Duik__1JANo button {
    font-size: 1.7vw;
    padding: 1vw 2vw;
    background-color: transparent;
    color: #fff;
    font-family: 'infini';
    border: 2px solid white;
    cursor: pointer;
}

.Duik_Duik__1JANo.Duik_Open__1Fbgf {
    height: 44vh;
    cursor: auto;
    cursor: initial;
    background-position: 50vw -16vh, left 30vh;
}

.Duik_Duik__1JANo.Duik_Open__1Fbgf p {
    margin-top: 0;
}

.Duik_Duik__1JANo.Duik_Medium__17eDu {
    height: 16vh;
    background-position: 50vw -16vh, left 10vh;
}
.Duik_Duik__1JANo.Duik_Medium__17eDu.Duik_ontscheppingOpen__2Oi9C {
    background-position: 50vw -44vh, left 10vh;
}

.Duik_Duik__1JANo.Duik_Small__2n1pm {
    height: 16vh;
    padding: 0.8vw 1.4vw;
    background-position: 50vw -10vw, left 10vh;
}

.Duik_Duik__1JANo.Duik_Smallest__1BDqC {
    height: 6vh;
    padding: 3vh 1.4vw;
    background-position: 50vw -6.4vw, left 10vh;
}

@media (max-width: 1024px) {
    .Duik_Duik__1JANo {
        background-image: url(/static/media/duik-in-het-onbekende-mobile.5723674c.png);
        background-repeat: no-repeat;
        background-position: left 12vh;
        background-size: 100% auto;
        background-blend-mode: normal;
   
    }

    .Duik_Duik__1JANo.Duik_Open__1Fbgf {
        background-position: left 30vh;
        background-size: 100% auto;
    }

    .Duik_Duik__1JANo.Duik_Medium__17eDu, .Duik_Duik__1JANo.Duik_Medium__17eDu.Duik_ontscheppingOpen__2Oi9C {
        background-position: left 10vh;
        background-size: 100% auto;
    }

    .Duik_Duik__1JANo.Duik_Small__2n1pm {
        background-position: left 9vh;
        background-size: 100% auto;
    }

    .Duik_Duik__1JANo.Duik_Smallest__1BDqC {
        background-position: left 9vh;
        background-size: 100% auto;
    }
    

    .Duik_Duik__1JANo h1 {
        font-size: 7.5vw;
        width: 100%;
        text-align: center;
        margin: 2vh auto 0.5vw auto;
    }

    .Duik_Duik__1JANo.Duik_Medium__17eDu h1 {
        margin: 4vh auto 0.5vw auto;
    }


    .Duik_Duik__1JANo.Duik_Small__2n1pm h1 {
        margin: 3vh auto 0.5vw auto;
    }

    .Duik_Duik__1JANo p {
        box-sizing: border-box;
        font-size: 3.5vw;
        width: 100%;
        padding: 0 10%;
        text-align: center;
    }

    .Duik_Duik__1JANo button {
        display: block;
        margin: auto;
    }
}

@media (max-width: 640px) {
    .Duik_Duik__1JANo p {
        font-size: 5vw;
    }

    .Duik_Duik__1JANo button {
        font-size: 5vw;
        padding: 2vw 4vw;
    }

}
.Aap_Aap__2x4S3 {
    box-sizing: border-box;
    color: #fff;
    display: block;
    cursor: pointer;
    float: left;
    width: 100%;
    height: 23vh;
    margin: 0;
    padding: 1.6vw 1.4vw;
    overflow: hidden;
    background-color: #003c5f;
    -webkit-transition: all 1s;
    transition: all 1s;
    -webkit-transition-timing-function: ease;
            transition-timing-function: ease;
    background-image: url(/static/media/de-toren-logo-blend.d109e3c7.png), url(/static/media/van-aap-naar-mens.1b57f65b.png);
    background-repeat: no-repeat, no-repeat;
    background-position: 50vw -46vh, center 8vh;
    background-size:  auto 92vh, 50vw auto;
    background-blend-mode: screen, normal;
    box-shadow: inset 0 -20px 20px -20px rgba(0,0,0,0.80);
    -moz-box-shadow: inset 0 -20px 20px -20px rgba(0,0,0,0.80);
    -webkit-box-shadow: inset 0 -20px 20px -20px rgba(0,0,0,0.80);
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .Aap_Aap__2x4S3 {
        background-image: url(/static/media/de-toren-logo.1f89e0c7.png), url(/static/media/van-aap-naar-mens.1b57f65b.png);
    }
}

@supports (-ms-ime-align:auto) {
    .Aap_Aap__2x4S3 {
        background-image: url(/static/media/de-toren-logo.1f89e0c7.png), url(/static/media/van-aap-naar-mens.1b57f65b.png);
    }
}

.Aap_Aap__2x4S3 h1 {
    font-weight: normal;
    font-style: normal;
    padding: 0;
    margin: 0;
    font-size: 3vw;
    width: 35vw;
}

.Aap_Aap__2x4S3 p {
    font-size: 1.3vw;
    width: 35vw;
    margin-top: 23vh;
    -webkit-transition: margin-top 1s;
    transition: margin-top 1s;
    -webkit-transition-timing-function: ease;
            transition-timing-function: ease;
}

.Aap_Aap__2x4S3 button {
    font-size: 1.7vw;
    padding: 1vw 2vw;
    background-color: transparent;
    color: #fff;
    font-family: 'infini';
    border: 2px solid white;
    cursor: pointer;
}

.Aap_Aap__2x4S3.Aap_Open__7EJdY {
    height: 44vh;
    cursor: auto;
    cursor: initial;
    background-position: 50vw -32vh, center 20vh;
}

.Aap_Aap__2x4S3.Aap_Open__7EJdY p {
    margin-top: 0;
}

.Aap_Aap__2x4S3.Aap_Medium__1qDfH {
    height: 16vh;
    background-position: 50vw -60vh, center 3vh;
}

.Aap_Aap__2x4S3.Aap_Medium__1qDfH.Aap_bloementuinOpen__22tGj {
    background-position: 50vw -32vh, center 3vh;
}

.Aap_Aap__2x4S3.Aap_Small__1EKtn {
    height: 16vh;
    padding: 0.8vw 1.4vw;
    background-position: 50vw -36vh, center 3vh;
}

.Aap_Aap__2x4S3.Aap_Smallest__1LW2Y {
    height: 6vh;
    padding: 0.8vw 1.4vw;
    background-position:50vw -34vh, center 3vh;
}

@media (max-width: 1024px) {
    .Aap_Aap__2x4S3 {
        background-image: url(/static/media/van-aap-naar-mens-mobile.42e22ac1.png);
        background-repeat: no-repeat;
        background-position: center bottom;
        background-blend-mode: normal;
        background-size: 50vw auto;
    }
    
    .Aap_Aap__2x4S3.Aap_Open__7EJdY {
        background-position: center 30vh;
        background-size: 50vw auto;
    }
    
    .Aap_Aap__2x4S3.Aap_Medium__1qDfH, .Aap_Aap__2x4S3.Aap_Medium__1qDfH.Aap_bloementuinOpen__22tGj{
        background-position: center 10vh;
        background-size: 50vw auto;
    }
    
    .Aap_Aap__2x4S3.Aap_Small__1EKtn {
        background-position: center 7vh;
        background-size: 50vw auto;
    }
    
    .Aap_Aap__2x4S3.Aap_Smallest__1LW2Y {
        background-position: center 5vh;
        background-size: 50vw auto;
    }

    .Aap_Aap__2x4S3 h1 {
        font-size: 7.5vw;
        width: 100%;
        text-align: center;
        margin: 2vh auto 0.5vw auto;
    }

    .Aap_Aap__2x4S3.Aap_Medium__1qDfH h1 {
        margin: 4vh auto 0.5vw auto;
    }


    .Aap_Aap__2x4S3.Aap_Small__1EKtn h1 {
        margin: 3vh auto 0.5vw auto;
    }

    .Aap_Aap__2x4S3 p {
        box-sizing: border-box;
        font-size: 3.5vw;
        width: 100%;
        padding: 0 10%;
        text-align: center;
    }

    .Aap_Aap__2x4S3 button {
        display: block;
        margin: auto;
    }
}

@media (max-width: 640px) {
    .Aap_Aap__2x4S3 p {
        font-size: 5vw;
    }

    .Aap_Aap__2x4S3 button {
        font-size: 5vw;
        padding: 2vw 4vw;
    }

}
.Bloementuin_Bloementuin__9xf8Q {
    box-sizing: border-box;
    color: white;
    cursor: pointer;
    display: block;
    float: left;
    width: 100%;
    height: 23vh;
    margin: 0;
    padding: 1.4vw 1.4vw;
    background-color: #FF6363;
    background-image: url(/static/media/de-toren-logo-blend.d109e3c7.png), url(/static/media/een-eeuwig-lachende-bloementuin.bb680210.png), -webkit-linear-gradient(right, rgba(255,0,20,1) 0%, rgba(255,99,99,0) 10%), -webkit-linear-gradient(342deg, #FF0000 0%, rgba(255,100,112,0.00) 46%);
    background-image: url(/static/media/de-toren-logo-blend.d109e3c7.png), url(/static/media/een-eeuwig-lachende-bloementuin.bb680210.png), linear-gradient(270deg, rgba(255,0,20,1) 0%, rgba(255,99,99,0) 10%), linear-gradient(108deg, #FF0000 0%, rgba(255,100,112,0.00) 46%);
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
    background-position: 50vw bottom, right 1vh, left bottom, left bottom;
    background-size:  auto 92vh, 50vw auto, cover, cover;
    background-blend-mode: screen, normal,normal, normal;
    overflow: hidden;
    -webkit-transition: all 1s;
    transition: all 1s;
    -webkit-transition-timing-function: ease;
            transition-timing-function: ease;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .Bloementuin_Bloementuin__9xf8Q {
        background-image: url(/static/media/de-toren-logo.1f89e0c7.png), url(/static/media/een-eeuwig-lachende-bloementuin.bb680210.png), -webkit-linear-gradient(right, rgba(255,0,20,1) 0%, rgba(255,99,99,0) 10%), -webkit-linear-gradient(342deg, #FF0000 0%, rgba(255,100,112,0.00) 46%);
        background-image: url(/static/media/de-toren-logo.1f89e0c7.png), url(/static/media/een-eeuwig-lachende-bloementuin.bb680210.png), linear-gradient(270deg, rgba(255,0,20,1) 0%, rgba(255,99,99,0) 10%), linear-gradient(108deg, #FF0000 0%, rgba(255,100,112,0.00) 46%);
    }
}

@supports (-ms-ime-align:auto) {
    .Bloementuin_Bloementuin__9xf8Q {
        background-image: url(/static/media/de-toren-logo.1f89e0c7.png), url(/static/media/een-eeuwig-lachende-bloementuin.bb680210.png), -webkit-linear-gradient(right, rgba(255,0,20,1) 0%, rgba(255,99,99,0) 10%), -webkit-linear-gradient(342deg, #FF0000 0%, rgba(255,100,112,0.00) 46%);
        background-image: url(/static/media/de-toren-logo.1f89e0c7.png), url(/static/media/een-eeuwig-lachende-bloementuin.bb680210.png), linear-gradient(270deg, rgba(255,0,20,1) 0%, rgba(255,99,99,0) 10%), linear-gradient(108deg, #FF0000 0%, rgba(255,100,112,0.00) 46%);
    }
}

.Bloementuin_Bloementuin__9xf8Q h1 {
    font-weight: normal;
    font-style: italic;
    padding: 0;
    margin: 0;
    font-size: 3vw;
    width: 35vw;
}

.Bloementuin_Bloementuin__9xf8Q p {
    font-size: 1.3vw;
    width: 35vw;
    margin-top: 23vh;
    -webkit-transition: margin-top 1.5s;
    transition: margin-top 1.5s;
    -webkit-transition-timing-function: ease;
            transition-timing-function: ease;
}

.Bloementuin_Bloementuin__9xf8Q button {
    font-size: 1.7vw;
    padding: 1vw 2vw;
    background-color: rgba(255,100,112,0.5);
    color: #fff;
    font-family: 'infini';
    border: 2px solid white;
    cursor: pointer;
}

.Bloementuin_Bloementuin__9xf8Q.Bloementuin_Open__1uW_z {
    height: 44vh;
    cursor: auto;
    cursor: initial;
}

.Bloementuin_Bloementuin__9xf8Q.Bloementuin_Open__1uW_z p {
    margin-top: 0;
}

.Bloementuin_Bloementuin__9xf8Q.Bloementuin_Medium__mhkxA {
    padding: 0.8vw 1.4vw;
    height: 16vh;
}

.Bloementuin_Bloementuin__9xf8Q.Bloementuin_Small__2fz-4 {
    height: 16h;
    padding: 0.8vw 1.4vw;
    background-position: 50vw -77vh, right -4vh, left bottom, left bottom;
}

.Bloementuin_Bloementuin__9xf8Q.Bloementuin_Smallest__OIqxY {
    height: 6vh;
    padding: 0.5vh 1.4vw;
}

@media (max-width: 1024px) {
    .Bloementuin_Bloementuin__9xf8Q {
        background-image: url(/static/media/een-eeuwig-lachende-bloementuin.bb680210.png), -webkit-linear-gradient(right, rgba(255,0,20,1) 0%, rgba(255,99,99,0) 10%), -webkit-linear-gradient(342deg, #FF0000 0%, rgba(255,100,112,0.00) 46%);
        background-image: url(/static/media/een-eeuwig-lachende-bloementuin.bb680210.png), linear-gradient(270deg, rgba(255,0,20,1) 0%, rgba(255,99,99,0) 10%), linear-gradient(108deg, #FF0000 0%, rgba(255,100,112,0.00) 46%);
        background-repeat: no-repeat, no-repeat, no-repeat;
        background-position: right 1vh, left bottom, left bottom;
        background-size: 100% auto, cover, cover;
        background-blend-mode: normal, normal, normal;
    }

    .Bloementuin_Bloementuin__9xf8Q.Bloementuin_Open__1uW_z {
        background-position: right bottom, left bottom, left bottom;
    }

    .Bloementuin_Bloementuin__9xf8Q h1 {
        font-size: 7.5vw;
        width: 100%;
        text-align: center;
        margin: 2vh auto 0.5vw auto;
    }

    .Bloementuin_Bloementuin__9xf8Q.Bloementuin_Medium__mhkxA h1 {
        margin: 4vh auto 0.5vw auto;
    }


    .Bloementuin_Bloementuin__9xf8Q.Bloementuin_Small__2fz-4 h1 {
        margin: 3vh auto 0.5vw auto;
    }

    .Bloementuin_Bloementuin__9xf8Q p {
        box-sizing: border-box;
        font-size: 3.5vw;
        width: 100%;
        padding: 0 10%;
        text-align: center;
    }

    .Bloementuin_Bloementuin__9xf8Q button {
        display: block;
        margin: auto;
    }
}

@media (max-width: 640px) {
    .Bloementuin_Bloementuin__9xf8Q p {
        font-size: 5vw;
    }

    .Bloementuin_Bloementuin__9xf8Q button {
        font-size: 5vw;
        padding: 2vw 4vw;
    }

}
.Toolbar_Toolbar__1oESL {
    display: flex;
    justify-content: space-between;
    float: left;
    width: 100%;
    height: 8vh;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.Toolbar_Toolbar__1oESL img {
    width: auto;
    height: 2.4vh;
    float: left;
    margin:  2.8vh 0 0 2.5vw;
}

.Toolbar_Toolbar__1oESL .Toolbar_SideItem__2X7ng {
    width: 8vw; 
}

.Toolbar_Toolbar__1oESL .Toolbar_Menu__1vFAb {
    width: 8vh;
    height: 8vh;
    float: right;
    margin: 0 1.8vw;
    border: 0;
    background: url(/static/media/hamburger.c6043a64.svg) no-repeat;
    background-size: 8vh;
    background-position: center center;
    cursor: pointer;
}

.Toolbar_Toolbar__1oESL .Toolbar_Menu__1vFAb.Toolbar_Open__1MoaH {
    background: url(/static/media/close.1470cf90.svg) no-repeat; 
    background-size: 8vh;
    background-position: center center;
}

.Toolbar_Toolbar__1oESL button:focus {
    outline: none;
}

.Toolbar_Toolbar__1oESL h1 {
    display: block;
    cursor: pointer;
    margin-top: 2.3vh;
    font-family: 'adieu';
    font-size: 3vh;
    text-transform: uppercase;
}

.Layout_Content__3bU1_ {
    float: left;
    display: block;
    width: 100%;
    height: 92vh;
    overflow: hidden;    
}

