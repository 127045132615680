/* Slider */
.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

.slick-dots {
    margin-left: 0;
}

.slick-dots {
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}

.slick-dots li, .slick-dots li button {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.slick-dots li {
    position: relative;
    display: inline-block;
    margin: 0 5px;
    padding: 0;
}

.slick-dots li button:before {
    font-family: slick;
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: "\2022";
    text-align: center;
    opacity: .25;
    color: #000;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-slider:before {
    content: '';
    position: absolute;
    box-shadow: inset 30px 0 30px -30px #000,
                inset -30px 0 30px -30px #000;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    pointer-events: none;
    cursor: pointer;
}

.slick-slider img {
    width: 98%;
    height: auto;
    align-self: center;
    margin: 0 auto;
}

.slick-arrow.slick-prev, .slick-arrow.slick-next {
    background-color: rgba(0, 0, 0, 0);
    border: none;
    position: absolute;
    background-image: url('./assets/images/prevnext.svg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
    top: 0;
    z-index: 200;
    height: 100%;
    width: 6vw;
    text-indent:-9999px;
    cursor: pointer;
}

.slick-arrow.slick-prev:focus, .slick-arrow.slick-next:focus  {
    outline: none;
}

.slick-arrow.slick-prev {
    left: 0;
}

.slick-arrow.slick-next {
    right: 0;
    transform: scaleX(-1);
}