.Menu {
    box-sizing: border-box;
    background-color: black;
    color: white;
    display: block;
    float: left;
    width: 100%;
    height: 0;
    margin: 0;
    padding: 0 1.4vw;
    overflow: hidden;
    overflow-y: scroll;
    -webkit-transition: all 1s;
    transition: all 1s;
    transition-timing-function: ease;
    scroll-behavior: smooth;
}

.Menu.Open {
    height: auto;
    padding-bottom: 2vh;
    height: 68vh;
    max-height: 68vh;
    overflow-x: none;
    overflow-y: auto;
}

.Menu .Tvmenu {
    width: 98%;
    margin: 0 1%;
    height: auto;
}
.Menu .Tvmenu h2 {
    width: 100%;
    border-bottom: 1px solid #ababab;
}
.Menu .Tvmenu a {
    color: white;
    text-decoration: underline;
}


.Menu .Tvmenu p {
    text-align: left;
}

.Menu .Tvmenu .Gridcontainer {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 1em;
    margin: 0;
    padding: 0;
}

/*.Menu .Tvmenu .Aflevering {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    background-color: #3e3e3e;
    margin: 0;
    padding: 0;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    padding-top:56.25%;
    height: 0;
    cursor: pointer;
    pointer-events: none;
    text-align: center;
}

.Menu .Tvmenu .Gridcontainer .Aflevering {
    display: inline-grid;
}*/

.Menu .Tvmenu .Wrapper a {
    text-align: center;
    text-decoration: none;
    position: absolute;
    top: 0; bottom: 0; left: 0; right: 0;
    width: 100%;
}

.Menu .Tvmenu .Wrapper a:focus {
    outline: none;
}

.Menu .Tvmenu .Wrapper p, .Menu .Tvmenu .Wrapper h3 {
    color: white;
    text-align: center;
    text-decoration: none;
    text-shadow: 2px 2px black;
    cursor: pointer;
    line-height: 1.5em;
    pointer-events: none;
}

.Menu .Tvmenu .Wrapper h3 {
    font-size: 1.5em;
    margin-top: -38%;
    text-transform: capitalize;
    font-weight: normal;

}

.Menu .Tvmenu .Wrapper p {
    font-size: 1em;
}

.Menu .Tvmenu ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.Menu .Tvmenu h3 {
    text-transform: uppercase;
}

.Menu .Wrapper {
    width: 100%;
    padding-bottom: 56%;
    position: relative;
}

@media (max-width: 640px) {
    .Menu {
        padding: 0 3vw;
    }

    .Menu.Open {
        padding: 1.5vw 3vw;
    }

    .Menu .Tvmenu {
        float: none;
        width: 100%;
        margin: 0;
    }
}

