.Toolbar {
    display: flex;
    justify-content: space-between;
    float: left;
    width: 100%;
    height: 8vh;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.Toolbar img {
    width: auto;
    height: 2.4vh;
    float: left;
    margin:  2.8vh 0 0 2.5vw;
}

.Toolbar .SideItem {
    width: 8vw; 
}

.Toolbar .Menu {
    width: 8vh;
    height: 8vh;
    float: right;
    margin: 0 1.8vw;
    border: 0;
    background: url('./assets/images/hamburger.svg') no-repeat;
    background-size: 8vh;
    background-position: center center;
    cursor: pointer;
}

.Toolbar .Menu.Open {
    background: url('./assets/images/close.svg') no-repeat; 
    background-size: 8vh;
    background-position: center center;
}

.Toolbar button:focus {
    outline: none;
}

.Toolbar h1 {
    display: block;
    cursor: pointer;
    margin-top: 2.3vh;
    font-family: 'adieu';
    font-size: 3vh;
    text-transform: uppercase;
}
