.Aap {
    box-sizing: border-box;
    color: #fff;
    display: block;
    cursor: pointer;
    float: left;
    width: 100%;
    height: 23vh;
    margin: 0;
    padding: 1.6vw 1.4vw;
    overflow: hidden;
    background-color: #003c5f;
    -webkit-transition: all 1s;
    transition: all 1s;
    transition-timing-function: ease;
    background-image: url('../../../assets/images/de-toren-logo-blend.png'), url('./assets/images/van-aap-naar-mens.png');
    background-repeat: no-repeat, no-repeat;
    background-position: 50vw -46vh, center 8vh;
    background-size:  auto 92vh, 50vw auto;
    background-blend-mode: screen, normal;
    box-shadow: inset 0 -20px 20px -20px rgba(0,0,0,0.80);
    -moz-box-shadow: inset 0 -20px 20px -20px rgba(0,0,0,0.80);
    -webkit-box-shadow: inset 0 -20px 20px -20px rgba(0,0,0,0.80);
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .Aap {
        background-image: url('../../../assets/images/de-toren-logo.png'), url('./assets/images/van-aap-naar-mens.png');
    }
}

@supports (-ms-ime-align:auto) {
    .Aap {
        background-image: url('../../../assets/images/de-toren-logo.png'), url('./assets/images/van-aap-naar-mens.png');
    }
}

.Aap h1 {
    font-weight: normal;
    font-style: normal;
    padding: 0;
    margin: 0;
    font-size: 3vw;
    width: 35vw;
}

.Aap p {
    font-size: 1.3vw;
    width: 35vw;
    margin-top: 23vh;
    -webkit-transition: margin-top 1s;
    transition: margin-top 1s;
    transition-timing-function: ease;
}

.Aap button {
    font-size: 1.7vw;
    padding: 1vw 2vw;
    background-color: transparent;
    color: #fff;
    font-family: 'infini';
    border: 2px solid white;
    cursor: pointer;
}

.Aap.Open {
    height: 44vh;
    cursor: initial;
    background-position: 50vw -32vh, center 20vh;
}

.Aap.Open p {
    margin-top: 0;
}

.Aap.Medium {
    height: 16vh;
    background-position: 50vw -60vh, center 3vh;
}

.Aap.Medium.bloementuinOpen {
    background-position: 50vw -32vh, center 3vh;
}

.Aap.Small {
    height: 16vh;
    padding: 0.8vw 1.4vw;
    background-position: 50vw -36vh, center 3vh;
}

.Aap.Smallest {
    height: 6vh;
    padding: 0.8vw 1.4vw;
    background-position:50vw -34vh, center 3vh;
}

@media (max-width: 1024px) {
    .Aap {
        background-image: url('./assets/images/van-aap-naar-mens-mobile.png');
        background-repeat: no-repeat;
        background-position: center bottom;
        background-blend-mode: normal;
        background-size: 50vw auto;
    }
    
    .Aap.Open {
        background-position: center 30vh;
        background-size: 50vw auto;
    }
    
    .Aap.Medium, .Aap.Medium.bloementuinOpen{
        background-position: center 10vh;
        background-size: 50vw auto;
    }
    
    .Aap.Small {
        background-position: center 7vh;
        background-size: 50vw auto;
    }
    
    .Aap.Smallest {
        background-position: center 5vh;
        background-size: 50vw auto;
    }

    .Aap h1 {
        font-size: 7.5vw;
        width: 100%;
        text-align: center;
        margin: 2vh auto 0.5vw auto;
    }

    .Aap.Medium h1 {
        margin: 4vh auto 0.5vw auto;
    }


    .Aap.Small h1 {
        margin: 3vh auto 0.5vw auto;
    }

    .Aap p {
        box-sizing: border-box;
        font-size: 3.5vw;
        width: 100%;
        padding: 0 10%;
        text-align: center;
    }

    .Aap button {
        display: block;
        margin: auto;
    }
}

@media (max-width: 640px) {
    .Aap p {
        font-size: 5vw;
    }

    .Aap button {
        font-size: 5vw;
        padding: 2vw 4vw;
    }

}