.Bloementuin {
    box-sizing: border-box;
    color: white;
    cursor: pointer;
    display: block;
    float: left;
    width: 100%;
    height: 23vh;
    margin: 0;
    padding: 1.4vw 1.4vw;
    background-color: #FF6363;
    background-image: url('../../../assets/images/de-toren-logo-blend.png'), url('./assets/images/een-eeuwig-lachende-bloementuin.png'), linear-gradient(270deg, rgba(255,0,20,1) 0%, rgba(255,99,99,0) 10%), linear-gradient(108deg, #FF0000 0%, rgba(255,100,112,0.00) 46%);
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
    background-position: 50vw bottom, right 1vh, left bottom, left bottom;
    background-size:  auto 92vh, 50vw auto, cover, cover;
    background-blend-mode: screen, normal,normal, normal;
    overflow: hidden;
    transition: all 1s;
    transition-timing-function: ease;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .Bloementuin {
        background-image: url('../../../assets/images/de-toren-logo.png'), url('./assets/images/een-eeuwig-lachende-bloementuin.png'), linear-gradient(270deg, rgba(255,0,20,1) 0%, rgba(255,99,99,0) 10%), linear-gradient(108deg, #FF0000 0%, rgba(255,100,112,0.00) 46%);
    }
}

@supports (-ms-ime-align:auto) {
    .Bloementuin {
        background-image: url('../../../assets/images/de-toren-logo.png'), url('./assets/images/een-eeuwig-lachende-bloementuin.png'), linear-gradient(270deg, rgba(255,0,20,1) 0%, rgba(255,99,99,0) 10%), linear-gradient(108deg, #FF0000 0%, rgba(255,100,112,0.00) 46%);
    }
}

.Bloementuin h1 {
    font-weight: normal;
    font-style: italic;
    padding: 0;
    margin: 0;
    font-size: 3vw;
    width: 35vw;
}

.Bloementuin p {
    font-size: 1.3vw;
    width: 35vw;
    margin-top: 23vh;
    -webkit-transition: margin-top 1.5s;
    transition: margin-top 1.5s;
    transition-timing-function: ease;
}

.Bloementuin button {
    font-size: 1.7vw;
    padding: 1vw 2vw;
    background-color: rgba(255,100,112,0.5);
    color: #fff;
    font-family: 'infini';
    border: 2px solid white;
    cursor: pointer;
}

.Bloementuin.Open {
    height: 44vh;
    cursor: initial;
}

.Bloementuin.Open p {
    margin-top: 0;
}

.Bloementuin.Medium {
    padding: 0.8vw 1.4vw;
    height: 16vh;
}

.Bloementuin.Small {
    height: 16h;
    padding: 0.8vw 1.4vw;
    background-position: 50vw -77vh, right -4vh, left bottom, left bottom;
}

.Bloementuin.Smallest {
    height: 6vh;
    padding: 0.5vh 1.4vw;
}

@media (max-width: 1024px) {
    .Bloementuin {
        background-image: url('./assets/images/een-eeuwig-lachende-bloementuin.png'), linear-gradient(270deg, rgba(255,0,20,1) 0%, rgba(255,99,99,0) 10%), linear-gradient(108deg, #FF0000 0%, rgba(255,100,112,0.00) 46%);
        background-repeat: no-repeat, no-repeat, no-repeat;
        background-position: right 1vh, left bottom, left bottom;
        background-size: 100% auto, cover, cover;
        background-blend-mode: normal, normal, normal;
    }

    .Bloementuin.Open {
        background-position: right bottom, left bottom, left bottom;
    }

    .Bloementuin h1 {
        font-size: 7.5vw;
        width: 100%;
        text-align: center;
        margin: 2vh auto 0.5vw auto;
    }

    .Bloementuin.Medium h1 {
        margin: 4vh auto 0.5vw auto;
    }


    .Bloementuin.Small h1 {
        margin: 3vh auto 0.5vw auto;
    }

    .Bloementuin p {
        box-sizing: border-box;
        font-size: 3.5vw;
        width: 100%;
        padding: 0 10%;
        text-align: center;
    }

    .Bloementuin button {
        display: block;
        margin: auto;
    }
}

@media (max-width: 640px) {
    .Bloementuin p {
        font-size: 5vw;
    }

    .Bloementuin button {
        font-size: 5vw;
        padding: 2vw 4vw;
    }

}