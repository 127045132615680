.Logline {
    box-sizing: border-box;
    display: block;
    float: left;
    width: 100%;
    height: 0;
    margin: 0;
    padding: 0 1.4vw;
    overflow: hidden;
    -webkit-transition: height 1.5s;
    transition: height 1.5s;
    transition-timing-function: ease;
    text-align: center;
}

.Logline.Open {
    height: 30vh;
}

.Logline p {
    font-size: 2vw;
    padding: 0 20vw;
}

.Logline button {
    font-family: 'infini';
    font-size: 1.3vw;
    padding: 1vw 2vw;
    display: block;
    margin: auto;
    background: white;
    border: 1px solid black;
}

.Logline button:focus {
    outline: none;
}

@media (max-width: 640px) {
    .Logline.Open {
        height: 15vh;
    }
    .Logline p {
        font-size: 3vw;
    }
    .Logline button {
        font-size: 3vw;
    }
}